import { useQuery } from '@tanstack/vue-query'
import type { Branding } from '~/types/branding'

export const useStoreBranding = defineStore('branding', () => {
  const _brandings = ref<Branding | null>(null)

  const getBrandingStorageKey = (companyId?: number) => {
    const id = companyId ?? useStoreApp().companyId
    return `branding-${id}`
  }

  const brandings = computed(() => {
    const key = getBrandingStorageKey()

    if (_brandings.value) return _brandings.value

    try {
      const inStorage = localStorage.getItem(key)
      return JSON.parse(inStorage || 'null')
    } catch {
      return null
    }
  })

  const fetchBrandings = (companyId: number) => {
    return useQuery({
      queryKey: ['branding', companyId],
      queryFn: async () => {
        const branding = (await useNuxtApp().$api(`/api/creator/company/${companyId}/brandings/`)) as Branding
        _brandings.value = branding

        try {
          const key = getBrandingStorageKey(companyId)
          localStorage.setItem(key, JSON.stringify(branding))
        } catch (exception) {
          // eslint-disable-next-line no-console
          console.warn(`Failed to save in localStorage: ${exception}`)
        }
        return branding
      },
      staleTime: 6400000 // 2 hours
    })
  }

  return { brandings, fetchBrandings }
})
